<template>
  <div class="item" @click="toDetail()">
    <el-image
      class="item_img"
      :src="require('@as/event/banner.png')"
    ></el-image>
    <div class="item_title">掌握国际并购规则，为中英资本跨境 投资护航活动</div>
    <div class="item_row">
      <el-image
        class="item_label_1"
        :src="require('@as/event/row_icon_1.png')"
      ></el-image>
      <div class="item_value">北京</div>
    </div>
    <div class="item_row">
      <el-image
        class="item_label"
        :src="require('@as/event/row_icon_2.png')"
      ></el-image>
      <div class="item_value">2020.08.12</div>
      <div class="item_btn" :class="{ active: item == 1, finish: item == 2 }">
        报名中
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toDetail() {
      this.$router.push("eventDetail");
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  margin-bottom: 20px;
  padding-bottom: 30px;
  width: 271px;

  box-sizing: border-box;
  background: #fff;
  .item_img {
    width: 269px;
    height: 130px;
  }
  .item_title {
    padding: 20px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
    line-height: 26px;
  }

  .item_row {
    margin-top: 16px;
    padding: 0 20px;
    display: flex;
    align-items: flex-end;
    &:first-child {
      margin-top: 44px;
    }
    .item_label_1 {
      margin-right: 3px;
      width: 14px;
      height: 20px;
    }
    .item_label {
      width: 18px;
      height: 18px;
    }
    .item_value {
      margin-left: 14px;
      margin-bottom: 4px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #808080;
    }
    .item_btn {
      margin-left: auto;
      padding: 7px 17px;
      border-radius: 4px;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      background: #1c9ab1;

      &.active {
        background: #f08d1d;
      }
      &.finish {
        background: #939494;
      }
    }
  }
}
</style>